import React, {useState} from 'react'
import Page from './../components/page'
import SEO from './../components/seo'
import AboutModal from './../components/about-modal'

import Wordmark from './../assets/svg/Wordmark.svg'
import Monogram from './../assets/svg/Monogram.svg'
import Pattern from './../assets/svg/Pattern.svg'

const Home = () => {
  const [isModalShown, setIsModalShown] = useState(false)

  return (
    <Page className='home'>
        <SEO
            title='Seal Ventures'
            description='Real estate seed funding empowering tomorrow.'
            keywords={[`seal ventures`, `real estate seed funding`, `real estate venture capital`]}
        />

        <AboutModal visibility={isModalShown} onHide={() => setIsModalShown(false)} />

        <nav className='nav'>
            <img src={Wordmark} className='wordmark' alt='' />
            <button className='about-link' onClick={() => setIsModalShown(true)}>About Us</button>
        </nav>

        <h3 className='headline'>
            There is a gap in accessible <br/>
            real estate seed funding for <br/>
            underserved communities.
        </h3>

        <p className='description'>
            We back the country’s most community-centric developers. We support them in building thriving real-estate ecosystems. We invest in emerging talent and undervalued land. We power socioeconomic change.
        </p>

        <a className='cta h6' href='mailto:info@sealventures.com' target='_blank' rel='noopener noreferrer'>
            Get in touch
            <svg className='arrow' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"/>
            </svg>
        </a>

        <div className='decorations-container'>
            <img src={Monogram} className='monogram' alt='' />
            <img src={Pattern} className='pattern' alt='' />
        </div>
    </Page>
  )
}

export default Home
